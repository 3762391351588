<template>
  <div>
    <a-modal
      v-model="visibleModalManagePeriode"
      class="mod"
      :title="status.isEdit ? 'Form Ubah Shift' : 'Form Tambah Shift'"
      @cancel="toggleModalManagePeriode"
      centered
    >
      <form-periode
        :dataPeriode="newPeriode"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManagePeriode">
          Batal
        </a-button>
        <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManagePeriode" @click="handleOkModalManagePeriode">
          {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
        <div class="ml-auto">
          <a-button @click.prevent="toggleModalManagePeriode('add')" size="large" type="primary"><a-icon type="plus" />Tambah Periode Kehadiran</a-button>
        </div>
      </div>
      <div class="mt-4">
        <a-table
          :columns="periodeColumns"
          :data-source="periodeDataTable"
          :pagination="periodePagination"
          :loading="periodeLoadingTable"
          bordered
        >
          <div slot="aksi" slot-scope="value, record">
            <a-button
              @click.prevent="toggleModalManagePeriode('edit', record)"
              class="mr-3 text-warning border border-warning"
              size="large"
            >
              <a-icon type="edit"/>Ubah
            </a-button>
            <a-button
              @click.prevent="handleDeletePeriode(record)"
              class="text-danger border border-danger"
              size="large"
            >
              <a-icon type="delete" />Hapus
            </a-button>
          </div>
        </a-table>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
const formPeriode = () => import('@/components/app/AdminHR/PeriodeKehadiran/FormPeriodeKehadiran')

const periodeColumns = [
  {
    title: 'Bulan',
    dataIndex: 'bulan',
    key: 'bulan',
    scopedSlots: { customRender: 'bulan' },
  },
  {
    title: 'Tahun',
    dataIndex: 'tahun',
    key: 'tahun',
    scopedSlots: { customRender: 'tahun' },
  },
  {
    title: 'Tanggal Awal',
    dataIndex: 'tanggal_awal',
    key: 'tanggal_awal',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'tanggal_awal' },
  },
  {
    title: 'Tanggal Akhir',
    dataIndex: 'tanggal_akhir',
    key: 'tanggal_akhir',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'tanggal_akhir' },
  },
  {
    title: 'Status',
    dataIndex: 'status_periode',
    key: 'status_periode',
    align: 'center',
    scopedSlots: { customRender: 'status_periode' },
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
export default {
  name: 'PeriodeKehadiran',
  components: {
    formPeriode,
  },
  data() {
    return {
      periodeColumns,
      visibleModalManagePeriode: false,
      loadingActionModalManagePeriode: false,
      loadingDeletePeriode: false,
      periodeLoadingTable: false,
      periodeDataTable: [],
      periodePagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newPeriode: {
        bulan: null,
        tahun: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        status_periode: null,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload

      this.newPeriode[column] = value
    },
    toggleModalManagePeriode(status, data) {
      this.visibleModalManagePeriode = !this.visibleModalManagePeriode
      if (!this.visibleModalManagePeriode) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newPeriode = {
            bulan: null,
            tahun: null,
            tanggal_awal: null,
            tanggal_akhir: null,
            status_periode: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPeriode = {
            bulan: data.bulan,
            tahun: data.tahun,
            tanggal_awal: moment(data.tanggal_awal),
            tanggal_akhir: moment(data.tanggal_akhir),
            status_periode: data.status_periode,
          }
        }
      }
    },
    periodeIsExist() {
      this.fetchDataPeriode()
      const isExist = Boolean(this.periodeDataTable.find(periode =>
        periode.tahun === parseInt(this.newPeriode.tahun) && periode.bulan.toLowerCase() === this.newPeriode.bulan.toLowerCase(),
      ))
      return isExist
    },
    handleOkModalManagePeriode() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah periode kehadiran ini? perubahan ini akan mempengaruhi sistem absensi pegawai' : 'Apakah anda yakin ingin membuat periode kehadiran baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Semua kolom wajib diisi',
        })
      } else {
        if (this.periodeIsExist() && this.status.isCreate) {
          return this.$notification.error({
            message: 'Gagal',
            description: 'Periode Kehadiran yang anda buat sudah ada disistem. Gunakan Ubah untuk mengubah periode kehadiran',
          })
        }
        console.log(this.periodeIsExist())
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManagePeriode = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataPeriode: this.newPeriode,
            } : {
              dataPeriode: this.newPeriode,
            }
            this.$store.dispatch(`absensiKaryawan/${this.status.isEdit ? 'EDIT' : 'POST'}_PERIODE_KEHADIRAN`, payload)
              .then(isSuccess => {
                this.loadingActionModalManagePeriode = false
                this.toggleModalManagePeriode()
                this.fetchDataPeriode()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Periode Kehadiran berhasil diubah' : 'Periode Kehadiran baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Periode Kehadiran gagal diubah' : 'Periode Kehadiran baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleDeletePeriode(data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin menghapus periode kehadiran <b>{data.bulan}</b> ? perubahan ini akan mempengaruhi sistem absensi pegawai</div>
        ),
        onOk: () => {
          this.periodeLoadingTable = true
          this.loadingDeletePeriode = true
          this.$store.dispatch('absensiKaryawan/DELETE_PERIODE_KEHADIRAN', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataPeriode()
              this.loadingDeletePeriode = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Periode Kehadiran berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Periode Kehadiran gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    fetchDataPeriode(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.periodeLoadingTable = true
      this.$store.dispatch('absensiKaryawan/FETCH_PERIODE_KEHADIRAN', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.periodeLoadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          // console.log(res)
          this.periodeDataTable = res.map(el => {
            return {
              key: el.id,
              id: el.id,
              bulan: el.bulan,
              tahun: el.tahun,
              tanggal_awal: moment(el.tanggal_awal).format('DD MMMM YYYY'),
              tanggal_akhir: moment(el.tanggal_akhir).format('DD MMMM YYYY'),
              status_periode: el.status_periode,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataPeriode()
  },
  computed: {
    isValidateForm() {
      if (this.newPeriode.bulan && this.newPeriode.tanggal_awal && this.newPeriode.tanggal_akhir) {
        return false
      }
      return true
    },
  },
}
</script>

<style>

</style>
